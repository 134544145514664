import { createContext, useContext } from 'react';

import type { RootPageContext } from '#types/page';

type PageContextType = RootPageContext;

const PageContext = createContext<PageContextType>(null as any);

export const PageContextProvider = PageContext.Provider;

export const usePageContext = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePageContext must be used within a PageContextProvider');
  }
  return context;
};
